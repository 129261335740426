<template>
    <el-dialog class="popup_dialog" title="模板设置" :visible.sync="isPopupEdit" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :destroy-on-close="false" width="1020px">

        <div class="indexFloor">
            <div class="storeFloor admin" v-if="dataInfo.type === 1">
                <div class="floorItem">
                    <div class="title" :class="{on: active === 'header1'}" @click="toChangeEdit('header1', 1, 1, form.textArea.header)">{{form.textArea.header.title}}</div>

                    <div class="goods">
                        <a href="javascript:void(0)" class="item" v-for="(item, i) in form.textArea.goodsList" :class="{on: active === 'good' + i}" :key="i" @click="toChangeEdit('good' + i, 2, i, item)">
                            <div class="image">
                                <el-image :src="item.img" fit="contain"></el-image>
                            </div>

                            <span class="name">{{item.name}}</span>

                            <span class="price">¥ {{item.price}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>


        <el-form ref="form" :model="form" label-width="82px" size="small" style="padding-top: 20px">
            <template v-if="activeJson.type === 1">
<!--                <div class="flexFloorHead">-->
<!--                    <h1 class="title">模块名称</h1>-->
<!--                </div>-->
                <el-form-item label="模块名称：" prop="headerTitle">
                    <el-input v-model="form.headerTitle" autocomplete="off" placeholder="请输入模块名称" @input="headerTitleChange" style="width: 300px"></el-input>
                </el-form-item>
            </template>

            <template v-if="activeJson.type === 2">
                <el-form-item label="商品名称：" prop="name">
                    <el-input v-model="form.good.name" autocomplete="off" placeholder="请输入商品名称" @input="goodNameChange" style="width: 300px"></el-input>
                </el-form-item>

                <el-form-item label="商品价格：" prop="price">
                    <el-input v-model="form.good.price" autocomplete="off" placeholder="请输入商品价格" @input="goodPriceChange" style="width: 300px"></el-input>
                </el-form-item>

                <el-form-item label="图片：" prop="img">
                    <el-upload
                            class="avatar-uploader"
                            :action="uploadURL"
                            :show-file-list="false"
                            :on-success="(res) => uploadSuccess(res)">
                        <img v-if="form.good.img" :src="form.good.img" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div slot="tip" class="el-upload__tip">注：该图请使用尺寸为{{form.good.size}}像素的jpg,gif,png图片格式上传使用</div>
                    </el-upload>
                </el-form-item>

                <el-form-item label="操作：" prop="url">
                    <el-select v-model="form.good.type" placeholder="请选择分类" style="width: 120px" @change="(val) => linkTypeChange(val, 0)">
<!--                        <el-option label="链接地址" value="1"></el-option>-->
                        <el-option label="商品SPU" value="2"></el-option>
<!--                        <el-option label="类目编号" value="3"></el-option>-->
                    </el-select>

                    <el-input v-model="form.good.url" autocomplete="off" :placeholder="getHoldText(form.good.type)" @input="(val) => linkUrlChange(val, 0)" style="width: 300px; margin-left: 16px"></el-input>
                </el-form-item>
            </template>
            <el-form-item>
                <el-button type="primary" @click="submitForm" :loading="isSubmitLoading">提  交</el-button>
                <el-button @click="close">关  闭</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import {putAdminStoreFloor} from "../../../../api/admin/store";
import {imageURL, uploadURL} from "../../../../utils/config";

export default {
  name: "floorSetting",
  props: {
    isPopupEdit: {
      type: Boolean,
      default: false
    },
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {
        textArea: null,
        headerTitle: '',
        good: {
          name: '',
          price: '',
          img: '',
          size: '',
          type: '2',
          url: ''
        }
      },
      active: '',
      activeJson: {
        type: null, // 类型 1-标题1, 2-good1
        index: null, // 序号
        value: null
      },
      isSubmitLoading: false,
      imageURL,
      uploadURL,
    }
  },
  watch: {
    dataInfo () {
      if (JSON.stringify(this.dataInfo).length > 2) {
        this.form.textArea = JSON.parse(this.dataInfo.textArea)
        this.active = ''
        this.activeJson.type = null
        this.activeJson.item = null
        this.activeJson.index = null
      }
    }
  },
  methods: {
    submitForm() {
      let isError = false
      this.form.textArea.goodsList.forEach(item => {
        if (item.url === '' || item.url === null) {
          isError = true
        }
      })

      if (isError) {
        this.$message({
          message: '请设置操作',
          type: 'warning',
          showClose: true,
        });
        return false
      }

      this.isSubmitLoading = true
      putAdminStoreFloor(this.dataInfo.id, {
        data: {
          textArea: JSON.stringify(this.form.textArea)
        }
      }).then(res => {
        this.isSubmitLoading = false
        if (res.code === 0) {
          this.$message({
            message: '模板设置成功',
            type: 'success',
            showClose: true,
          });
          this.$emit('success')
        }
      }).catch(() => {
        this.isSubmitLoading = false
      })
    },
    close () {
      this.$emit('close')
    },
    toChangeEdit (active, type, index, value) {
      this.active = active
      this.activeJson.type = type
      this.activeJson.index = Number(index)
      this.activeJson.value = value

      if (type === 1) {
        this.form.headerTitle = value.title
      }

      if (type === 2) {
        this.form.good.name = value.name
        this.form.good.desc = value.desc
        this.form.good.price = value.price
        this.form.good.img = value.img
        this.form.good.size = value.size
        this.form.good.type = value.type
        this.form.good.url = value.url
      }
    },
    headerTitleChange (val) {
      if (this.activeJson.type === 1) {
        this.form.textArea.header.title = val
      }
    },
    linkTypeChange (val) {
      if (this.activeJson.type === 2) {
        this.form.textArea.goodsList[this.activeJson.index].type = val
      }
    },
    linkUrlChange (val) {
      if (this.activeJson.type === 2) {
        this.form.textArea.goodsList[this.activeJson.index].url = val
      }
    },
    goodNameChange (val) {
      if (this.activeJson.type === 2) {
        this.form.textArea.goodsList[this.activeJson.index].name = val
      }
    },
    goodPriceChange (val) {
      if (this.activeJson.type === 2) {
        this.form.textArea.goodsList[this.activeJson.index].price = val
      }
    },
    uploadSuccess (res) {
      if (this.activeJson.type === 2) {
        this.form.good.img = this.imageURL + res.data
        this.form.textArea.goodsList[this.activeJson.index].img = this.imageURL + res.data
      }
    },
    getHoldText (type) {
      if (type === '1') {
        return '请输入链接名称'
      }

      if (type === '2') {
        return '请输入商品SPU'
      }

      if (type === '3') {
        return '请输入类目编号'
      }
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
    @import "../../../../assets/style/store";
</style>
